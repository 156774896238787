<template>
    <div class="text-center">
        <van-swipe :autoplay="3000" :loop="true" @change="onchange" ref="el" class="duration-300" :show-indicators="false">
            <van-swipe-item v-for="(k,i) in config.list" :key="i">
                <img :src="k.src" @dragstart.prevent @click="jump(k)" ref="imgs" v-lazy="k.src"
                @mousedown="start" @mouseup="end" class="inline-block"
                >
            </van-swipe-item>
        </van-swipe>
    </div>
</template>

<script>
import {Swipe, SwipeItem} from 'vant'
export default {
    name: 'CSwiper',
    components: {
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem
    },
    data() {
        return {
            _config: {},
            index: 0,
            imgs: null,
            el: null
        }
    },
    props: {
        config: {
            type: Object,
            default: () => ({})
        }
    },
    created() {
       
    },
    methods: {
        start(e) {
            this.mv = false
            this.x = e.clientX || 0
            this.y = e.clientY || 0
        },
        end(e) {
           if(e.clientX) {
            this.mv = (Math.abs(this.x - e.clientX)) > 5 || (Math.abs(this.y - e.clientY)) > 5;
           } 
        },
        onchange(i) {
            const img = this.$refs.imgs[i];
            if(img.complete) {
                this.$refs.el.$el.style.height = this.$refs.imgs[i].offsetHeight + 'px'
            }
        },
        jump(k) {
            if(this.mv) return;
            if(k.id) return this.$router.push('/' + k.id)
        }
    }
}
</script>