<template>
    <div class="c-nav__container">
        <div class="c-nav">
            <div class="c-nav__left">
                <template v-if="config.menu">
                    <van-icon name="wap-nav" @click="showPopup"/>
                    <van-popup  v-model="show" position="left" get-container="body">
                        <div class="c-menu__container">
                            <div class="c-menu__wrap">
                                <c-menu :menu="config.menu" @close="close"></c-menu>
                            </div>
                        </div>
                    </van-popup>
                </template>
            </div>
            <div class="c-nav__center">
                <img :src="config.logo" v-if="config.logo">
            </div>
            <div class="c-nav__right">
                <template v-if="config.search">
                    <van-icon name="search" @click="jump"/>
                </template>
            </div>
        </div>
        <div class="c-van__popup"></div>
    </div>
</template>

<script>
import { Popup } from 'vant';
export default {
    name: 'CNav',
    components: {
        [Popup.name]: Popup
    },
    data() {
        return {
            show: false
        }
    },
    props: {
        config: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        close() {
            this.show = false;
        },
        showPopup() {
            this.show = true;
        },
        jump() {
            this.$router.push('/product/search')
        }
    }
}
</script>

<style lang="less">
    .slide-enter-active, .slide-leave-active {
        left: 0;
        transition: .3s;
    }
    .c-menu__container {
        height: 100vh;
        overflow: auto;
        background: #fff;
        padding: 0 20px;
        width: 200px;
        box-sizing: border-box;
        .c-menu__wrap {
            padding: 20px 0;
        }
    }
    .c-nav__container {
        height: 80px;
        margin: 0 auto;
    }
    .c-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        z-index: 99;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: #fff;
        border-bottom: 1px solid #eee;
        .van-icon-search, .van-icon-wap-nav {
            font-size: 24px;
        }
        .c-nav__left {
            margin-left: 15px;
        }
        .c-nav__right {
            margin-right: 15px;
        }
        .c-nav__center {
            img {
                height: 40px;
                position: absolute;
                left: 50%;
                top: 20px;
                transform: translateX(-50%);
            }
        }
    }
</style>