<template>
  <div id="app">
    <Keep-alive :include="includes" :max="10">
      <router-view/>
    </Keep-alive>
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      includes: []
    }
  },
  created(){
    this.keepRoute()
    // window.oncontextmenu = function() {
    //   return false;
    // }
  },
  watch: {
    $route() {
      this.keepRoute()
    }
  },
  methods: {
    select() {
      this.test = '1'
    },
    keepRoute() {
      if(this.$route.meta.keepAlive && !this.includes.includes(this.$route.name)) {
        this.includes.push(this.$route.name)
      }
    }
  }
}
</script>

<style>
#app {
  /* font-family: 'Avenir', Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-family: 'SimHei' !important;
  /* margin-top: 60px; */
  margin: auto;
}
</style>
