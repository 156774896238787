
import Vue from 'vue'
import App from './App'
import i18n from './locales'
import router from './router'
import axios_ from './http'
import {Icon,Empty,Loading,NavBar,Toast} from 'vant'
import VueLazyload from 'vue-lazyload'
import { getCurrency } from '@/utils/util'
import CUI from './package'
import '@vant/touch-emulator'
import lazy from './assets/lazy.png'
import Template from './template'
import './assets/tailwindcss.css'

getCurrency()
Vue.use(VueLazyload, {
  loading: lazy
})
Vue.use(Icon)
Vue.use(Empty)
Vue.use(Loading)
Vue.use(NavBar)
Vue.use(Toast)
Vue.use(CUI)
Vue.use(Template)
Vue.config.productionTip = false
Vue.prototype.$http = axios_.axios_ins;
Vue.prototype.$https = axios_.axios_form_ins
Vue.prototype.$file = 'https://api.cjzy.love/'
// Vue.prototype.$file = 'http://192.168.31.150:8880'

new Vue({
  el: '#app',
  router,
  i18n,
  render: h => h(App)
})
