import {setToken} from './auth'

export function getCurrency(path, login = false) {
    let aa = [
        {
            name:'台湾',
            currencySymbol:'NT$',

        },
        {
            name:'香港特别行政區',
            currencySymbol:'HK$',

        }
    ]
    window.localStorage.setItem('currencyLits', JSON.stringify(aa))
    
} 

export function atPresentCurrency(name){
   let currencyLits = JSON.parse(window.localStorage.getItem('currencyLits'))
   let currencySymbol = ''
   if (currencyLits) {
    
    currencyLits.forEach((liem,i)=>{
        if (liem.name===name) {
            currencySymbol = liem.currencySymbol
        }
    })
   }else{

   }
   return currencySymbol
}