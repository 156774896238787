import Nav from './nav'
import Title from './title'
import Swiper from './swiper'
import List from './list'
import Footer from './footer'
import Menu from './menu'

export default {
    install(Vue) {
        Vue.component(Nav.name, Nav)
        Vue.component(Title.name, Title)
        Vue.component(Swiper.name, Swiper)
        Vue.component(List.name, List)
        Vue.component(Footer.name, Footer)
        Vue.component(Menu.name, Menu)
    }
}