import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  linkActiveClass: 'active',
  routes: [
        {
          path: '/',
          name: 'Home',
          component: resolve => require(['../views/Home'], resolve),
          meta: {
            keepAlive: true,
            title: ''
          }
        },
        // 商品详情页
        {
          path: '/:id',
          name: 'detail',
          props: true,
          component: resolve => require(['../views/detail/index'], resolve),
          meta: {
            keepAlive: false,
            title: '商品详情页'
          }
        },
         // 商品搜索页
         {
          path: '/product/search',
          name: 'searchPage',
          component: resolve => require(['../views/searchPage/index'], resolve),
          meta: {
            keepAlive:false,
            title: '商品搜索页'
          }
        }, {
          path: '/product/order/:id',
          name: 'order',
          props: true,
          component: resolve => require(['../views/order/index'], resolve),
          meta: {
            keepAlive:false,
            title: '订单'
          }
        }, {
          path: '/product/orderSearch',
          name: 'orderSearch',
          component: resolve => require(['../views/order/search'], resolve),
          meta: {
            keepAlive:false,
            title: '订单查询'
          }
        }
      ]
})
