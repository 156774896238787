
<script>
export default {
    name: 'CTitle',
    props: {
        config: {
            type: Object,
            default: () => ({})
        }
    },
    render(h) {
        return h('h2', {class: 'text-center font-bold my-4 text-lg'}, this.config.title || '标题')
    }
}
</script>