<template>
    <div class="flex flex-wrap">
        <div class="w-1/2 sm:w-1/3 md:w-1/4" v-for="(k,i) in list">
            <div class="p-2" @click="jump(k)">
                <div>
                <img v-lazy="k.img" class="w-full h-full object-cover">
                </div>
                <div class="line-clamp-2">{{ k.title }}</div>
                <div class="text-center font-extrabold" style="color:darkorange;">
                    <span>{{ k.fb }}</span> <span>{{ k.price }}</span>
                </div>
                <div class="text-center line-through text-gray-400" v-if="k.discountPrice">
                    <span>{{ k.fb }}</span> <span>{{ k.discountPrice }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CList',
    data() {
        return {
            list: [
                {
                    img: 'https://d25hop7fqwo0ni.cloudfront.net/6d2675ee0b7342a3b6d30643e2b379cf.jpg?x-oss-process=style/webp',
                    title: 'vant组件库SwipeCell增加点击不触发功能 - 木灵鱼儿vant组件库SwipeCell增加点击不触发功能 - 木灵鱼儿',
                    price: 998,
                    fb: 'NT$',
                    discountPrice: 1098
                }, {
                    img: 'https://d25hop7fqwo0ni.cloudfront.net/6d2675ee0b7342a3b6d30643e2b379cf.jpg?x-oss-process=style/webp',
                    title: 'vant组件库SwipeCell增加点击不触发功能 - 木灵鱼儿vant组件库SwipeCell增加点击不触发功能 - 木灵鱼儿',
                    price: 998,
                    fb: 'NT$',
                    discountPrice: 0
                }, {
                    img: 'https://d25hop7fqwo0ni.cloudfront.net/6d2675ee0b7342a3b6d30643e2b379cf.jpg?x-oss-process=style/webp',
                    title: 'vant组件库SwipeCell增加点击不触发功能 - 木灵鱼儿vant组件库SwipeCell增加点击不触发功能 - 木灵鱼儿',
                    price: 998,
                    fb: 'NT$',
                    discountPrice: 1098
                }, {
                    img: 'https://d25hop7fqwo0ni.cloudfront.net/6d2675ee0b7342a3b6d30643e2b379cf.jpg?x-oss-process=style/webp',
                    title: 'vant组件库SwipeCell增加点击不触发功能 - 木灵鱼儿vant组件库SwipeCell增加点击不触发功能 - 木灵鱼儿',
                    price: 998,
                    fb: 'NT$',
                    discountPrice: 1098
                }, {
                    img: 'https://d25hop7fqwo0ni.cloudfront.net/6d2675ee0b7342a3b6d30643e2b379cf.jpg?x-oss-process=style/webp',
                    title: 'vant组件库SwipeCell增加点击不触发功能 - 木灵鱼儿vant组件库SwipeCell增加点击不触发功能 - 木灵鱼儿',
                    price: 998,
                    fb: 'NT$',
                    discountPrice: 0
                }
            ]
        }
    },
    props: {

    },
    methods: {
        jump(k) {
            this.$router.push('/' + k.id)
        }
    }
}
</script>