<template>
    <div class="py-10 bg-black text-center">
        <div v-if="config.logo">
            <img :src="config.logo" class="w-11 h-11 inline-block">
        </div>
        <img src="../../assets/payment.png" class="inline-block h-6 mt-5 sm:h-10 ">
    </div>
</template>

<script>
export default {
    name: 'CFooter',
    props: {
        config: {
            type: Object,
            default: () => ({})
        }
    }
}
</script>