const login = {
    'title': '欢迎登录',
}
const commodity ={
  discount:'官网独家折扣',
  freeShipping:'免运费',
  freeShipping1:'15天鑒賞期',
  flashSale:'限时抢购',
  endOfDistance:"距离结束",
  payOnDelivery:"貨到付款",
  supermarketPickup:"超市取货",
  distribution:'新竹/黑貓配送',
  SnapUp:"立即抢购",
  orderInquiry:"訂單查詢",
  immediatelyBuy:"立即購買",
  productsIntroduction:'商品介紹',
  latestEvaluation:'最新評價',
  salesVolume:'销量',
  satisfaction:'满意度',
  auxiliaryWordForOrdinalNumbers:'第',
  piece:'件',
  selected:'已选'
}
  
export default {
  login,commodity
}