<template>
    <div class="c-menu">
        <template v-for="k in menu">
            <template v-if="k.children && k.children.length">
                <div class="c-menu__title"><span @click="jump(k)">{{ k.title }}</span><span @click="collapse=!collapse" :class="{'is-collapse': collapse}"></span></div>
                <c-menu :menu="k.children" @close="close" :class="{'c-menu__collapse': collapse}"></c-menu>
            </template>
            <template v-else>
                <div class="c-menu__title"><span @click="jump(k)">{{ k.title }}</span></div>
            </template>
        </template>
    </div>
</template>

<script>
export default {
    name: 'CMenu',
    data() {
        return {
            collapse: false
        }
    },
    props: {
        menu: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        jump(k) {
            this.$emit('close')
            this.$nextTick(() => {
                this.$router.push('/product/search')
            })
        }
    }
}
</script>

<style lang="less">
.c-menu > .c-menu {
    margin-left: 15px;
}
.c-menu__collapse {
    display: none;
}
.c-menu__title {
    font-size: 15px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span:first-child {
        cursor: pointer;
        &:hover {
            color: red;
        }
        & + span {
            width: 0;
            height: 0;
            display: inline-block;
            margin-left: 10px;
            border-bottom: 4px solid currentColor;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid transparent;
            transform: translateY(-2px);
            &.is-collapse {
                transform: rotate(180deg) translateY(-2px);
            }
        }
    }
}
</style>