const templateMap = {
    'home-1': () => import('./home/1'),
    'product-1': () => import('./product/1'),
    'order-1': () => import('./product/1/order.vue')
}

export default {
    install(Vue) {
        Object.keys(templateMap).forEach(k => {
            Vue.component(k, templateMap[k])
        })
    }
}