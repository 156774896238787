import axios from 'axios'
// import store from './store/index'
import qs from 'qs';
import md5 from 'js-md5'
import router from './router'
import {_cryptoSignature,getsignature,signature,sortJson,verify} from '../src/seting'
import {Toast} from 'vant'

// 设置基础路径
axios.defaults.baseURL = 'https://api.cjzy.love/'
// axios.defaults.baseURL = 'http://171.214.0.151:8880'
var axios_ins = axios.create()
var axios_form_ins = axios.create()   // 提交form表单的实例
axios_form_ins.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios_ins.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

axios_ins.interceptors.request.use(
    config => {

      

      // if (window.localStorage.getItem("PRIVATE")&&window.localStorage.getItem("publicKey")) {
        
      // }else{
      //   window.sessionStorage.setItem('userId', "");
      // }
      if(config.method=="post"){
        let timestamp = new Date().getTime();
        config.data = {...config.data,timestamp };
        // console.log(config);

        let obj = sortJson(config.data)
        let sorted = ""

        for (let key in obj) {
          // console.log(key);
          // console.log(obj[key]) 
          sorted += `&${key}=${obj[key]}`
        }

       sorted = sorted.substr(1) 

        // let sorted = qs.stringify(sortJson(config.data));
        // console.log(sorted);
        // sorted = decodeURIComponent(sorted);

        //  console.log(sorted);
        let mds = md5(sorted);
        // console.log(mds);
        // console.log(config.data,PUBLIC);
        // 加签名
        let bas = signature(mds.toUpperCase(), window.localStorage.getItem("PRIVATE"));

        
        config.data = {...config.data,signature:bas.signBase64}

        config.data = qs.stringify(config.data);

        config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
      }

      

      
      if (localStorage.userToken) {
        config.headers.Authorization = "Basic "+ btoa(localStorage.userToken + ":")
      }
      let url = config.url.split('/')
      if(url[url.length-2]!='uploadFile'&&url[url.length-2]!='uploadImage'&&url[url.length-2]!='queryListByUrl'){
         
        let secretKeyId = window.localStorage.getItem("secretKeyId")
        let Tokenid = window.localStorage.getItem("tokenId")
        
        if(secretKeyId){
          secretKeyId = secretKeyId
        }
        config.headers.secretKeyId = secretKeyId;

        if (Tokenid) {
          config.headers.Tokenid = Tokenid;
        }
        
        
        config.headers.source = "1";
      }
      return config;
    }, err => {
      return Promise.reject(err);
  });
//响应后操作
axios_ins.interceptors.response.use(
  response =>{
    console.log(response);
    // console.log(window.location.pathname);
    if (response.data.signature&&response.config.url !="/api/kaptcha/getSecretKeyId") {
      let publicKey = window.localStorage.getItem("publicKey")//后台公钥
  
  
    // console.log("后台公钥：",publicKey,"22");
  
      let data = JSON.parse(JSON.stringify(response.data))
      
      // 删除
      if (response.data.signature) {
        delete data.signature;
      }
      
      let sorted = sortJson(data)
      // console.log(sorted);
    // console.log(JSON.stringify(sorted));
      // 排序结果md5
      let mds = md5(JSON.stringify(sorted))
      // console.log(mds);
      // 对返回结果验证签名
      let result = verify(response.data.signature, mds.toUpperCase(),`-----BEGIN PUBLIC KEY-----${publicKey}-----END PUBLIC KEY-----` )

      
      if (result == false) { 
        Toast('验签失败')
        if (window.location.pathname==="/mp/"||window.location.pathname==="/") {
          
        }else{
          window.sessionStorage.setItem('userId', '');
          router.push({name: 'Home'})
        }
        
        
        
      }
    }

    if(response.data.code == 2  && window.localStorage.getItem("userId")){
      // window.localStorage.removeItem("userData")
      // window.location.href = '/login/index'
      // router.push({name: 'login'})
    } else if (response.data.code == 100038) {//密钥过期
      getsignature()
      let PUBLIC = window.localStorage.getItem("PUBLIC")//前端公钥
      var bracketLeft = new RegExp('-----BEGIN PUBLIC KEY-----\r\n','g'); 
      let PUBLIC1 = PUBLIC.replace(bracketLeft,''); 
      var bracketLeft1 = new RegExp('-----END PUBLIC KEY-----','g'); 
      let PUBLIC2 = PUBLIC1.replace(bracketLeft1,'');

      axios_ins.post(`/api/kaptcha/getSecretKeyId`,{publicKey:PUBLIC2}).then((res)=>{
        console.log(res);
        
        if (res.data.code==0) {
          window.localStorage.setItem('publicKey', res.data.data.publicKey)
          window.localStorage.setItem('secretKeyId', res.data.data.secretKeyId)
        }
      })
      // axios({
      //     url: `http://171.214.0.151:8880/api/kaptcha/getSecretKeyId`,
      //     method: 'post',
      //     headers:{
      //       source:3
      //     },
      //     data:{publicKey:PUBLIC2},
      // }).then(res => {
      //     console.log(res);
      // })
      // .catch(res => {
      //     this.$notify.error("请求错误");
      // });

      // if (data.code==0) {
      //   storage.set('publicKey', data.data.publicKey);
      //   storage.set('secretKeyId', data.data.secretKeyId);
      // }
    }else{
      // this.$toast(response.data.msg);
    }
    return response
  },err => {
      return Promise.reject(err);
  }
)


axios_form_ins.interceptors.request.use(
    config => {
      if (config.method=="post"){
        const signature = _cryptoSignature(config.data)
        config.data = {...config.data,};
        let new_data = config.data
        if(!(new_data instanceof FormData)){
          new_data = new FormData()
          for(let key in config.data){
            if(typeof config.data[key] != 'undefined'){
              new_data.append(key, (typeof config.data[key] == 'object')?JSON.stringify(config.data[key]): config.data[key])
            }
          }
        }
        config.data = new_data
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      }
      if (localStorage.userToken) {
        config.headers.Authorization = "Basic"+ btoa(localStorage.userToken + ":")
      }

      let url = config.url.split('/')
      if(url[url.length-2]!='uploadFile'&&url[url.length-2]!='uploadImage'&&url[url.length-2]!='queryListByUrl'){
        let sessionId = '',
        userData = JSON.parse(window.localStorage.getItem("userData"))
        if(userData){
          sessionId = userData.sessionId
        }
        config.headers.sessionId = sessionId;
        config.headers.source = "4";
      }
      return config;
    }, err => {
      return Promise.reject(err);
    }
);

//响应后操作
axios_form_ins.interceptors.response.use(
  response =>{
    if(response.data.code == 2 && JSON.parse(window.localStorage.getItem("userData"))){
      window.localStorage.removeItem("userData")
      // window.location.href = '/login/index'
    }
    return response
  },err => {
      return Promise.reject(err);
  }
)
var axios_ = {
  axios_form_ins,
  axios_ins
}
export default axios_;
