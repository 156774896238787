import crypto from 'crypto-js'
import jsrsasign from 'jsrsasign'
const publicKey = 'F6888FAA84B6EC90320D5FCDF648BF4B'
export function _cryptoSignature(param, filterLength = 1000) {
    
}

// 私钥加签
export function signature(signData,priK) {
  



    // var prv = jsrsasign.KEYUTIL.getKey(PUBLIC );   //传入公钥
      // var iis  = prv.encrypt(signData);  //加密
    // console.log("jsutil:"+jsrsasignUtil.readFileHexByBin("./pri.key"));
    // 创建秘钥实例
    // console.log(priK);
    // console.log("dsds",signData);
    var key = jsrsasign.KEYUTIL.getKey(priK);
    // 指定签名算法 sha1对原文哈希
    let signature = new jsrsasign.KJUR.crypto.Signature({ alg: "SHA256withRSA" });   //SHA1withRSA  SHA256withRSA
    // 传入秘钥实例, 初始化
    signature.init(key);
    // 传入待签明文
    signature.updateString(signData);
    // 签名, 得到16进制字符结果
    let signResult = signature.sign();
    // 签名hex转base64
    
    let signBase64 = jsrsasign.hextob64(signResult);
    // console.log(signBase64);
    return {signBase64};
  }

  // 验签 
export function verify(signData, data ,pubk ) { 

  // signData: 加签之后得到的签文
  // data: 加签的数据 

  // console.log(signData, data ,pubk,a);

  // let signature=new jsrsasign.KJUR.crypto.Signature({alg:"SHA1withRSA",prvkeypem:priK});
  // signature.updateString(src);
  // 签名返回结果是16进制字符串，注意转码
  // let a = signature.sign();
  // let sign = jsrsasign.hextob64(a);

  try {
      // 验签
      let signatureVf = new jsrsasign.KJUR.crypto.Signature({ alg: "SHA256withRSA", prvkeypem: pubk });
      signatureVf.updateString(data);
      // console.log(jsrsasign.b64tohex(signData));
      let result = signatureVf.verify(jsrsasign.b64tohex(signData));
      // console.log("jsrsasign verify: " + result);
      
      return result;
  } catch (e) {
      console.error(e);
  }
}

export function getsignature() {
    
    var rsaKeypair = jsrsasign.KEYUTIL.generateKeypair('RSA',2048);
      let PUBLIC = jsrsasign.KEYUTIL.getPEM(rsaKeypair.prvKeyObj);  //获取公钥
      let PRIVATE = jsrsasign.KEYUTIL.getPEM(rsaKeypair.prvKeyObj,'PKCS8PRV');  //获取私钥
  
    // console.log(PUBLIC,PRIVATE);

    window.localStorage.setItem('PUBLIC', PUBLIC)
    window.localStorage.setItem('PRIVATE', PRIVATE)
  
  }

export function sortJson(obj) { 
    if (Array.isArray(obj)) {
      return obj.map(d => {
        return sortJson(d);
      })
    } else if (typeof obj === 'object'&& obj!= null) {
      let keys = Object.keys(obj);
      let o = {};
      keys.sort();
      keys.forEach(key => {
        if (Array.isArray(obj[key])) {
          o[key] = obj[key].map(d => {
            return sortJson(d);
          })
        } else if (typeof obj[key] === 'object') {
          o[key] = sortJson(obj[key]);
        } else {
          o[key] = obj[key];
        }
      });
      return o;
    } else {
      return obj;
    }
}


  export function get(parameter) {
    return request({
      url: api.get,
      method: 'post',
      data: parameter
    })
  } 