import VueI18n from 'vue-i18n'
import Vue from 'vue'


  
import zhUS from './lang/zh'
import enUS from './lang/en'
// 以下为语言包单独设置的场景，单独设置时语言包需单独引入

Vue.use(VueI18n)
const messages = {
  'zh_CN': zhUS,   // 中文语言包
  'en_US': enUS    // 英文语言包
}

const i18n = new VueI18n({
  silentTranslationWarn: true,
  locale : 'en_US',
  messages,
})

export default i18n
